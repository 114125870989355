<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('serviceDetails.title')"

        :paths="[{title:$t('settings.title'),link:'settings'}]"
    />

    <div>
      <filter-header
          :content="filterContent"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderData"

      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      ">
      <div class="d-flex flex-wrap flex-1">
        <b-col lg="12" md="12" class="px-0">

          <div class="w-table d-flex flex-column">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getData"
                :per-page="filterData.per_page"
                :page-number="filterData.page"
                :items="getData"
                :fields="tableFields"
                :custimized-items="[
                { name: 'name' },
                { name: 'description' },
                { name: 'status' },
                { name: 'action' },
              ]"
            >

              <template slot="name" slot-scope="{ data }">
                <name-tile
                    :name="data.item.name"
                    :image="data.item.image"
                />
              </template>

              <template slot="description" slot-scope="{ data }">
                <check-value :value="data.item.description"/>
              </template>


              <template slot="status" slot-scope="{ data }">
                <wameed-switch

                    :checked="data.item.status ===1 "
                    @onChange="(e)=>{e.preventDefault();  onChange(data.item)}"/>

              </template>

              <template slot="action" slot-scope="{ data }"
                        v-if="data.item.type==null">
                <b-dropdown
                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>

                  <b-dropdown-item
                      class="permission-edit-item"
                      v-permission="[$route.meta.pagePermissions.edit]"
                      @click="()=> openUpdateModal(data.item)"
                  >
                    <span class="text-regular-14 text-font-secondary">

                      <edit-icon class="mx-2"/>
                      {{ $t('btn.edit') }}
                    </span>
                  </b-dropdown-item>


                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotal"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotal.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>

      </div>

    </section>

    <service-detail-modal
        :visible="showModal"
        @close="showModal = false"
        :modal-data="modalData"
        @onComplete="()=>loadData()"
    ></service-detail-modal>


    <warning-modal
        variant="main"
        iconName="o-toggle-icon"
        :visible="showToggleModal"
        @close="closeToggleModal"
        @submitAction="toggleAction"
        :title="toggleModal.title"
        :subTitle="toggleModal.subTitle"
        :btnTitle="toggleModal.btn"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import WarningModal from "@/components/WarningModal";

import {WameedPagination, WameedSwitch, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import NameTile from "@/components/nameTile.table";
import serviceDetailModal from "@/views/pages/settings/services_detail/components/serviceDetailModal";
import CheckValue from "@/components/checkValue";

export default {
  components: {
    CheckValue,
    serviceDetailModal,
    NameTile,
    WarningModal,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WameedSwitch,
    WTables,
  },
  data() {
    return {
      showModal: false,
      modalData: null,

      showToggleModal: false,
      toggleModal: {
        title: this.$i18n.t('serviceDetails.toggle.inactive.title'),
        subTitle: this.$i18n.t('serviceDetails.toggle.inactive.sub_title'),
        btn: this.$i18n.t('serviceDetails.toggle.inactive.btn'),
      },
      toggleData: {id: 0, status: 0},
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('table.availability'),
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          translate: true,
          type: 'text',
          data: [
            {
              id: 'active',
              title: this.$i18n.t('status.available'),
            },
            {
              id: 'not_active',
              title: this.$i18n.t('status.not_available'),
            },
          ],
        },
      ],
      tableFields: [
        {
          key: 'index',
          label: '#',
          sortable: false,

        },
        {
          key: 'name',
          label: this.$i18n.t('serviceDetails.form.name'),
          sortable: false,
          tdClass: 'w-2/5'
        },
        {
          key: 'shortcut_name',
          label: this.$i18n.t('serviceDetails.short_name'),
          sortable: false,
          tdClass: 'w-1/5'
        },
        {
          key: 'description',
          label: this.$i18n.t('serviceDetails.description'),
          sortable: false,
          tdClass: 'w-1/5'
        },

        {
          key: 'status',
          label: this.$i18n.t('table.availability'),
          sortable: false,
          tdClass: 'w-1/5'
        },
        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/5'
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getData: 'admin/settings/services_detail/getData',
      getTotal: 'admin/settings/services_detail/getTotal',
    }),
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      _getData: 'admin/settings/services_detail/get',
    }),


    openUpdateModal(item) {

      this.$store.dispatch('admin/settings/services_detail/detail', {id: item.id}).then((items) => {
        this.showModal = true;
        this.modalData = {
          id: item.id,
          image: {name: item.image},
        };
        items.forEach((item) => {
          this.modalData['name_' + item['locale']] = item['name']
          this.modalData['shortcut_name_' + item['locale']] = item['shortcut_name']
          this.modalData['service_name_singular_' + item['locale']] = item['service_name_singular']
          this.modalData['service_name_plural_' + item['locale']] = item['service_name_plural']
          this.modalData['products_name_singular_' + item['locale']] = item['products_name_singular']
          this.modalData['products_name_plural_' + item['locale']] = item['products_name_plural']
          this.modalData['description_' + item['locale']] = item['description']
        })
      })

    },


    onChange(item) {
      let status = 1;
      if (item.status === 1) {
        this.toggleModal = {
          title: this.$i18n.t('serviceDetails.toggle.inactive.title'),
          subTitle: this.$i18n.t('serviceDetails.toggle.inactive.sub_title'),
          btn: this.$i18n.t('serviceDetails.toggle.inactive.btn'),
        }
        status = 0;
      } else {
        this.toggleModal = {
          title: this.$i18n.t('serviceDetails.toggle.active.title'),
          subTitle: this.$i18n.t('serviceDetails.toggle.active.sub_title'),
          btn: this.$i18n.t('serviceDetails.toggle.active.btn'),
        }

      }
      this.toggleData = {id: item.id, status: status};
      this.showToggleModal = true;
    },

    closeToggleModal() {

      this.showToggleModal = false;
    },
    toggleAction() {
      this.$store.dispatch('admin/settings/services_detail/toggle', this.toggleData)
      this.showToggleModal = false;
    },


    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page:1,
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },

    loadData() {

      this._getData(this.filterData);
    },


    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>
